import React from "react";

export const ConditionsOfCarriage = () => {
  return (
    <div className="conditionsOfCarriage">
      <h4 style={{textAlign: "center"}}>Conditions of Carriage</h4>
      <ol>
        <li>
          <p>
            All terms and conditions, liberties and exceptions of the Charter Party, dated as overleaf, are
            herewith
            incorporated. The Carrier shall in no case be responsible for loss of damage to cargo arisen
            prior to
            loading and after discharging.
          </p>
        </li>
        <li>
          <strong>General Paramount Clause</strong>
          <p>
            The Hague Rules contained in the International Convention for the Unification of certain rules
            relating to
            Bills of Lading, dated Brussels the 25th August 1924 as enacted in the country of shipment shall
            apply to
            this contract. When no such enactment is in force in the country of shipment, the corresponding
            legislation
            of the country of destination shall apply, but in respect of shipments to which no such
            enactments are
            compulsorily applicable, the terms of the said Convention shall apply.
          </p>
          <p>
            <i>Trades where Hague-Visby Rules apply.</i> In trades where the International Brussels Convention 1924 as
            amended by the Protocol signed at
            Brussels on
            February 23rd 1968 - The Hague-Visby Rules - apply compulsory the provisions of the respective
            legislation
            shall be considered incorporated in this Bill of Lading. The Carrier takes all reservations
            possible under
            such applicable legislation, relating to the period before loading and after discharging and
            while the goods
            are in the charge of another Carrier, and to deck cargo and live animals.
          </p>
        </li>
        <li>
          <strong>General Average</strong>
          <p>
            General Average shall be adjusted, stated and settled according to York-Antwerp Rules 1974, as
            amended 1990,
            in London unless another place is agreed in the Charter.
          </p>
          <p>
            {`Cargo's contribution to General Average shall be paid to the Carrier even when such average is the
            result of
            a fault, neglect or error of the Master, Pilot or Crew. The Carterers, Shippers and Consignees
            expressly
            renounce the Netherlands Commercial Code, Art. 700, and the Belgian Commercial Code, Party II. Art.
            148.`}
          </p>
        </li>
        <li>
          <strong>New Jason Clause</strong>
          <p>
            In the event of accident, danger, damage or disaster before or after the commencement of the voyage,
            resulting from any cause whatsoever, whether due to negligence or not, for which, or for the consequence
            of
            which, the Carrier is not responsible, by statute, contract or otherwise, the goods, Shippers, Consignees
            or
            owners of the goods shall contribute with the Carrier in general average to the payment of any sacrifices,
            losses or expenses of a general average nature that may be made or incurred and shall pay salvage and
            special charges incurred in respect of the goods.
          </p>
          <p>
            If a salving ship is owned or operated by the Carrier, salvage shall be paid for as fully as if the said
            salving ship or ships belonged to strangers. Such deposit as the Carrier or his agents may deem sufficient
            to cover the estimated contribution of the goods and any salvage and special charges thereon shall, if
            required, be made by the goods, Shippers, Consignees or owners of the goods to the Carrier before
            delivery.
          </p>
        </li>
        <li>
          <strong>Both-to-Blame Collision Clause</strong>
          <p>
            If the vessel comes into collision with another ship as a result of the negligence of the other ship and
            any
            act,
            neglect or default of the master, Mariner, Pilot or the servants of the Carrier in the navigation or in
            the
            management of the Vessel, the owners of the cargo carried hereunder will indemnity the Carrier against all
            loss or liability to the other or non-carrying ship or her Owners in so far as such loss or liability to
            the
            other or non-carrying ship or her Owners in so far as such loss or liability represents loss of, or damage
            to, or any claim whatsoever of the owners of said cargo, paid or payable by the other or non-carrying ship
            or her Owners to the owners of said cargo and set-off, recouped or recovered by the other or non-carrying
            ship or her Owners as part of their claim against the carrying Vessel or Carrier. The foregoing provisions
            shall also apply where the Owners, operators or those in charge of any ship or ships or objects other
            than,
            or in addition to, the colliding ships or objects are at fault in respect of a collision or contact.
          </p>
        </li>
      </ol>
    </div>
  )
}