import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {defaultTitleEscrowFactory} from '../../tradeTrust/titleEscrow';
import {getTokenRegistryAddress} from '../../tradeTrust/tokenRegistry';
import {retrieveFile} from '../../utils/files';
import {getWalletSigner} from '../../utils/wallet';
import {CloseRounded} from '@mui/icons-material';
import {Link as RouterLink} from 'react-router-dom';
import {DecentralisedRenderer} from '../DecentralisedRenderer/DecentralisedRenderer';
import {PartyResponse, ShipmentResponse} from '../../generated';
import {shipmentsApi} from '../../api/shipmentsApi';
import {useQueryClient} from '@tanstack/react-query';
import {
  TitleEscrow,
  TitleEscrow__factory,
  TitleEscrowFactory__factory,
  TradeTrustToken__factory,
} from '@govtechsg/token-registry/dist/contracts';
import {Alert, Breadcrumbs, Button, Link, Typography} from '@mui/material';
import {partiesApi} from '../../api/partiesApi';
import {ethers} from 'ethers';
import './TitleTransfer.css';
import {useConnectWallet} from '@web3-onboard/react';
import {verifyDoc} from '../../config/oa-verify';
import {ManageOwner} from './ManageOwner';
import {ManageHolder} from './ManageHolder';
import {ManageNominee} from './ManageNominee';
import {ManageBeneficiary} from './ManageBeneficiary';
import {ManageSurrenderDocument} from './ManageSurrenderDocument';
import {ManageShredDocument} from './ManageShredDocument';
import {ManageRejectDocument} from './ManageRejectDocument';
import {getAuthHeader} from '../../utils/getAuthHeader';
import {TokenHistoryInner} from './TokenHistoryInner';
import {PrintBtn} from './PrintBtn';
import {ConditionsOfCarriage} from "../../pages/ConditionsOfCarriage";

export const Item = styled(Paper)(({theme}) => ({
  padding: theme.spacing(1),
  borderRadius: '2px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: '1px solid #ccc',
}));

// TTManagement shows an overview for the current state of a tradetrust document on the blockchain.
// It shows which holder and beneficiary are currently set. Depending on the currently connected wallet,
// transfers of ownership and holder can be done here as well.
export const TitleTransfer = (props: props): ReactElement => {
  const [ttVaultId, setTTVaultId] = useState<string>('');
  const [verifying, setVerifying] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [connectedEscrow, setConnectedEscrow] = useState<TitleEscrow>();
  const [{wallet}, connect, disconnect] = useConnectWallet();
  const queryClient = useQueryClient();
  // Whether this is an active title escrow process; so it is not surrendered yet
  const [active, setActive] = useState<boolean>(false);
  const [shipment, setShipment] = useState<ShipmentResponse>();
  const [surrendered, setSurrendered] = useState<boolean>(false);
  const [parties, setParties] = useState<Array<PartyResponse>>([]);
  useRef(null);

  const handleWallet = () => {
    if (wallet) {
      disconnect(wallet);
      window.localStorage.removeItem('connectedWallets');
    } else {
      connect().then((r) => {
        console.log('Connected wallet ' + r[0].accounts[0]);
      });
    }
  };

  // Fetch shipment and tradetrust file to propagate data into the title escrow page
  const fetchAndSetData = useCallback(async (id: string): Promise<ShipmentResponse> => {
    const authHeader = await getAuthHeader();
    const partiesData = await partiesApi.partiesGet({headers: authHeader});
    setParties(filterParties(partiesData));
    const shipmentData = await shipmentsApi.shipmentsIdGet({id: id}, {headers: authHeader});
    setShipment(shipmentData);

    const ttDoc = shipmentData.docs?.find((doc) => doc.fileName.endsWith('tt'));
    const ttVaultId = ttDoc?.vaultId || '';
    setTTVaultId(ttVaultId);
    setTitleEscrowData(shipmentData);
    await verifyTTFile(ttVaultId);
    return shipmentData;
  }, []);

  // Refresh data when network switches
  useEffect(() => {
    fetchAndSetData(props.shipmentId);
  }, [fetchAndSetData, props.shipmentId]);

  function refreshData() {
    fetchAndSetData(props.shipmentId);
  }

  // Obtain all parties that have a valid public key; so only the parties we can transact with
  function filterParties(parties: Array<PartyResponse>): Array<PartyResponse> {
    return parties.filter((p) => ethers.utils.isAddress(p.publicKey || ''));
  }

  // Call the remote oa-verify api to check the validity of the wrapped doc
  async function verifyTTFile(ttVaultId: string) {
    setVerifying(true);
    const chainId = window.ethereum?.networkVersion;
    if (!chainId) {
      console.error('No wallet is connected to a valid ethereum network');
      return;
    }
    try {
      const isVerified = await verifyDoc(ttVaultId, parseInt(chainId));
      setVerified(isVerified);
      setError('');
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'An error occurred while verifying the document',
      );
      setVerified(false);
    }
    setVerifying(false);
  }

  // Fetch and refresh all title escrow data from blockchain state
  async function setTitleEscrowData(shipmentData: ShipmentResponse) {
    try {
      // This small hack makes sure that all child components are fully re-rendered when new title escrow data is
      // loaded from the blockchain
      setConnectedEscrow(undefined);

      const tokenId = shipmentData.tokenId;
      if (tokenId === undefined) {
        console.warn('No tokenID is set for this shipment');
        return;
      }

      const signer = getWalletSigner();
      const titleEscrowFactory = TitleEscrowFactory__factory.connect(
        defaultTitleEscrowFactory,
        signer,
      );

      // Connect to the title escrow
      const titleEscrowAddress = await titleEscrowFactory.getAddress(
        getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
        '0x' + tokenId,
      );
      const titleEscrow = TitleEscrow__factory.connect(titleEscrowAddress, signer);
      console.log('Title escrow connected, address: ' + titleEscrow.address);
      setConnectedEscrow(titleEscrow);

      // Find out if the document has been shredded yet or if it is still active
      const active = await titleEscrow.active();
      setActive(active);

      // If the owner of the token is the same as that of the token registry, it means that the document
      // has been surrendered
      const tokenRegistry = TradeTrustToken__factory.connect(
        getTokenRegistryAddress(parseInt(window.ethereum?.networkVersion)),
        signer,
      );
      console.log('tokenregistry address: ' + tokenRegistry.address);
      const owner = await tokenRegistry.ownerOf('0x' + tokenId);
      console.log('Owner of token: ' + owner);
      if (owner === tokenRegistry.address) {
        setSurrendered(true);
      } else {
        setSurrendered(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      <div className="no-print">
        <Breadcrumbs className="no-print" style={{fontSize: 35}} aria-label="breadcrumb">
          <Link underline="hover" component={RouterLink} color="inherit" to="/">
            Shipments
          </Link>
          <Link
            underline="hover"
            component={RouterLink}
            color="text.primary"
            to={`/shipments/${props.shipmentId}`}
          >
            {shipment?.name}
          </Link>
          <Link underline="none" color="text.primary">
            Manage title transfer
          </Link>
        </Breadcrumbs>

        <Box sx={{flexGrow: 1}} className="no-print">
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={8} id="doc-render-wrap">
              <Item>
                <DecentralisedRenderer vaultId={ttVaultId} isWrapped={true} templateType=""/>
              </Item>
            </Grid>
            <Grid item xs={4} id="manage-tt-item" className="no-print">
              <Item>
                {verifying ? (
                  <div>Verifying document...</div>
                ) : verified ? (
                  <div>
                    <CheckCircleOutlineIcon sx={{fontSize: '75px', color: 'success.main'}}/>
                    <Typography variant="h5">Document is valid</Typography>
                  </div>
                ) : (
                  <div>
                    <CloseRounded sx={{fontSize: '75px', color: 'error.main'}}/>
                    <Typography variant="body1">{error}</Typography>
                  </div>
                )}
              </Item>
              {surrendered && (
                <Item>
                  <Typography variant="h6" sx={{color: 'error.main'}}>
                    Surrendered to issuer
                  </Typography>
                </Item>
              )}
              {connectedEscrow && (
                <Item>
                  <Button
                    onClick={() => retrieveFile(ttVaultId)}
                    variant="contained"
                    sx={{my: 1}}
                  >
                    Download TradeTrust (.tt) file
                  </Button>
                  <PrintBtn connectedEscrow={connectedEscrow}/>
                </Item>
              )}
              {connectedEscrow &&
                (active ? (
                  <div>
                    <ManageOwner
                      connectedEscrow={connectedEscrow}
                      queryClient={queryClient}
                      refreshTitleEscrowData={refreshData}
                      parties={parties}
                    />
                    <ManageHolder
                      connectedEscrow={connectedEscrow}
                      queryClient={queryClient}
                      refreshTitleEscrowData={refreshData}
                      parties={parties}
                    />
                    <ManageNominee
                      connectedEscrow={connectedEscrow}
                      queryClient={queryClient}
                      refreshTitleEscrowData={refreshData}
                      parties={parties}
                    />
                    <ManageBeneficiary
                      connectedEscrow={connectedEscrow}
                      queryClient={queryClient}
                      refreshTitleEscrowData={refreshData}
                      parties={parties}
                    />
                    {surrendered ? (
                      <div>
                        <ManageShredDocument
                          connectedEscrow={connectedEscrow}
                          queryClient={queryClient}
                          refreshTitleEscrowData={refreshData}
                        />
                        <ManageRejectDocument
                          connectedEscrow={connectedEscrow}
                          queryClient={queryClient}
                          refreshTitleEscrowData={refreshData}
                        />
                      </div>
                    ) : (
                      <ManageSurrenderDocument
                        connectedEscrow={connectedEscrow}
                        queryClient={queryClient}
                        refreshTitleEscrowData={refreshData}
                      />
                    )}
                  </div>
                ) : surrendered ? (
                  <Item>
                    <Typography variant="body1">
                      The document was surrendered and accepted by the original issuer. It cannot be
                      transferred anymore.
                    </Typography>
                  </Item>
                ) : (
                  <Item>
                    <Typography variant="body1">The document is inactive.</Typography>
                  </Item>
                ))}
              {!wallet && (
                <Item>
                  <Alert severity="warning">
                    Please{' '}
                    <Link onClick={handleWallet} underline="hover" color="inherit" href="#">
                      <strong>connect a wallet</strong>
                    </Link>{' '}
                    to view more details.
                  </Alert>
                </Item>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="print-view">
        <div className="renderer-print-version" id="renderer-print-version">
          <DecentralisedRenderer vaultId={ttVaultId} isWrapped={true} templateType=""/>
        </div>

        <div className="token-history-print-version">
          <Typography variant="h5">Endorsement chain</Typography>
          <br/>
          <TokenHistoryInner/>
          <br/>
          <div className="conditions-of-carriage-print-version">
            <ConditionsOfCarriage/>
          </div>
        </div>

      </div>
    </div>
  );
};

interface props {
  shipmentId: string;
}

export interface titleEscrowTransfer {
  address: string;
}
