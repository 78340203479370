import { Alert, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import './TokenHistoryInner.css';
import React, { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
  getGraphClient,
  getTokenHistoryQuery,
  parseTitleEscrowAddress,
  parseTokenHistoryData,
  Snapshot,
} from './queryDocuments';
import { useQuery } from '@tanstack/react-query';
import { PartyResponse, ShipmentResponse } from '../../generated';
import { partiesApi } from '../../api/partiesApi';
import { shipmentsApi } from '../../api/shipmentsApi';
import { getAuthHeader } from '../../utils/getAuthHeader';
import QRCode from 'react-qr-code';
import { CheckCircle } from '@mui/icons-material';

export const TokenHistoryInner = (): ReactElement => {
  const { id } = useParams();
  let shipmentId = '';
  if (id !== undefined) {
    shipmentId = id;
  }

  const [snapshots, setSnapshots] = useState<Array<Snapshot>>([]);
  const [parties, setParties] = useState<Array<PartyResponse>>([]);
  const { isError } = useQuery(['shipment', shipmentId], () => fetchAndSetData(shipmentId), {
    retry: false,
  });
  const [noData, setNoData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [titleEscrowAddress, setTitleEscrowAddress] = useState<string>();

  // Obtain the url of the block explorer where all transactions that happened
  // for a single title escrow can be inspected
  function getTransactionExplorerUrl(): string {
    const chainId = parseInt(window.ethereum?.networkVersion);

    // Polygon network by default
    let url = 'https://mumbai.polygonscan.com/address/' + titleEscrowAddress + '#events';
    if (chainId === 11155111) {
      url = 'https://sepolia.etherscan.io/address/' + titleEscrowAddress + '#events';
    }
    if (chainId === 51) {
      url = 'https://apothem.xdcscan.io/tx/' + titleEscrowAddress + '#events';
    }
    return url;
  }

  // A short clickable link to the address page. If the address exists in the user's address book,
  // then the party name should also be shown here.
  function displayParty(address?: string, showFullAddress?: boolean): JSX.Element {
    if (!address) {
      return <></>;
    }
    const party = parties.find((p) => p.publicKey?.toLowerCase() === address.toLowerCase());
    if (party?.partyName) {
      return (
        <Link
          underline="hover"
          component={RouterLink}
          color="text.primary"
          to={`/address-book/${address}/edit`}
        >
          {showFullAddress && (
            <div>
              <Typography variant="h5">{party.partyName}</Typography>
              {/*TODO this is a duplicate field - what do we do with it?*/}
              {/*<Typography variant="subtitle2">{party.address?.addressName}</Typography>*/}
              <Typography variant="subtitle2">
                {party.address?.streetName} {party.address?.streetNumber}
              </Typography>
              <Typography variant="subtitle2">{party.address?.postCode}</Typography>
              <Typography variant="subtitle2">{party.address?.cityName}</Typography>
              <Typography variant="subtitle2">{party.address?.country}</Typography>
              <br />
            </div>
          )}
          <Typography variant="body1">{address}</Typography>
        </Link>
      );
    }
    return (
      <Link
        underline="hover"
        component={RouterLink}
        color="text.primary"
        to={`/address-book/${address}/edit`}
      >
        <Typography variant="body1">{address}</Typography>
      </Link>
    );
  }

  // Fetch shipment and tradetrust file to propagate data into the token history page
  const fetchAndSetData = useCallback(async (id: string): Promise<ShipmentResponse> => {
    setLoading(true);
    const authHeader = await getAuthHeader();
    const partiesData = await partiesApi.partiesGet({ headers: authHeader });
    setParties(partiesData);

    const shipmentData = await shipmentsApi.shipmentsIdGet({ id: id }, { headers: authHeader });
    const tokenId = shipmentData.tokenId;
    if (tokenId === undefined) {
      console.error('Token id is not attached to the shipment object');
      return shipmentData;
    }
    await fetchGraphClientData(tokenId);
    return shipmentData;
  }, []);

  // Fetch and refresh all title escrow data from blockchain state;
  // use the token ID to fetch the history using the graphClient instance
  async function fetchGraphClientData(tokenId: string) {
    try {
      const result = await getGraphClient(parseInt(window.ethereum?.networkVersion)).query({
        query: gql(getTokenHistoryQuery('0x' + tokenId)),
      });
      console.log(result.data);
      if (!result.data || result.data.tokens.length < 1) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setLoading(false);
      setTitleEscrowAddress(parseTitleEscrowAddress(result));
      setSnapshots(parseTokenHistoryData(result));
    } catch (err) {
      setLoading(false);
      console.error('Error fetching data: ', err);
    }
  }

  function formatDate(timestamp: string) {
    return new Date(parseInt(timestamp) * 1000).toLocaleString('nl');
  }

  // Translate action types into readable action names
  function formatActionName(actionName: string) {
    if (actionNames.has(actionName)) {
      return actionNames.get(actionName);
    }
    return actionName;
  }

  // We should not be showing the full display address (street, postcode etc.) for intermediate
  // transfers
  function showFullAddress(actionName: string) {
    switch (actionName) {
      case 'HolderTransfer':
      case 'BeneficiaryTransfer':
      case 'NominationRevocation':
      case 'Nomination':
        return false;
      default:
        return true;
    }
  }

  const actionNames = new Map<string, string>([
    ['HolderTransfer', 'Holder transfer'],
    ['BeneficiaryTransfer', 'Owner transfer'],
    ['NominationRevocation', 'Nomination revocation'],
  ]);

  if (id === undefined) {
    return <p>Error: shipment ID not found</p>;
  }

  return (
    <>
      <Grid container width={1500}>
        <Grid item xs={1.5}>
          <Typography variant="h4">Action</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography variant="h4">Owner</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography variant="h4">Holder</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography variant="h4">Nominee</Typography>
        </Grid>

        {loading && (
          <Grid container>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}

        {isError && (
          <Grid container>
            <Grid item>
              <Alert severity="warning">Error fetching token history data.</Alert>
            </Grid>
          </Grid>
        )}

        {noData && (
          <Grid container>
            <Grid item>
              <Alert severity="warning">
                No token history data found. The token may be issued over a different blockchain
                network; Try switching the connected network in your wallet.
              </Alert>
            </Grid>
          </Grid>
        )}

        {snapshots.map((step, index) => (
          <div key={index}>
            <Grid key={index} minWidth={1500} container>
              <Grid item xs={1.5}>
                <Grid container>
                  <Grid item xs={2.5}>
                    <CheckCircle fontSize="large" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h6">{formatActionName(step.action)}</Typography>
                    <Typography variant="body2">{formatDate(step.timestamp)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3.5}>
                {displayParty(step.beneficiary?.id, showFullAddress(step.action))}
              </Grid>
              <Grid item xs={3.5}>
                {displayParty(step.holder?.id, showFullAddress(step.action))}
              </Grid>
              <Grid item xs={3.5}>
                {displayParty(step.nominee?.id, showFullAddress(step.action))}
              </Grid>
            </Grid>
            <br />
            <Divider variant={'fullWidth'} orientation={'horizontal'} color="black" />
            <br />
          </div>
        ))}

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              View transactions on blockchain:
              <br />
              <a href={getTransactionExplorerUrl()} target="_blank" rel="noreferrer">
                {getTransactionExplorerUrl()}
              </a>
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="print-only">
          <Grid item xs={12}>
            <br />
            <QRCode value={getTransactionExplorerUrl()} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
